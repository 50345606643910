import { defineStore } from "pinia";
import type { Card, CardList } from "~/types/cards";
import type { CatalogEntry } from "~/types/catalog";
import type { Filter, sortOption } from "~/types/filter";

export const useSearchStore = defineStore("search", {
  state: () => ({
    searchTerm: "",
    filters: [] as Filter[],
    sortOptions: [
      { value: '{"title": 1}', text: "A - Z" },
      { value: '{"title": -1}', text: "Z - A" },
      { value: '{"year": 1}', text: "Year: High to Low" },
      { value: '{"year": -1}', text: "Year: Low to High" },
    ],
    selectedSort: '{"title": 1}',
    selectedSubCategory: "",
    mobileFiltersOpen: false,
    cards: [] as Card[],
    cardslist: {} as CardList,
  }),
  actions: {
    setSearchTerm(searchTerm: string) {
      this.searchTerm = searchTerm;
    },
    setSortOption(value: string) {
      this.selectedSort = value;
    },
    setFilterOption(value: Filter) {
      // Check if the filter already exists
      const existingIndex = this.filters.findIndex(
        (filter) =>
          filter.field === value.field &&
          JSON.stringify(filter.value) === JSON.stringify(value.value),
      );

      if (existingIndex >= 0) {
        // If the filter exists, remove it
        this.filters.splice(existingIndex, 1);
      } else {
        // If the filter does not exist, add it
        this.filters.push(value);
      }
    },
    setSubCategories(option: CatalogEntry) {
      if (this.selectedSubCategory == option.value) {
        this.selectedSubCategory = "";
      } else {
        this.selectedSubCategory = option.value;
      }
    },
    checkFilter(option: Filter) {
      return this.filters.some(
        (filter) =>
          filter.field === option.field &&
          JSON.stringify(filter.value) === JSON.stringify(option.value),
      );
    },
    checkSort(option: sortOption) {
      if (option.value == this.selectedSort) {
        return true;
      } else {
        return false;
      }
    },
    checkSubCategory(option: CatalogEntry) {
      if (option.value === this.selectedSubCategory) {
        return true;
      } else {
        return false;
      }
    },
    async getCards(
      limit: number,
      skip: number,
      keywords: string,
      productName: string,
      sort: string,
      filters: Filter[],
      reset = false,
    ) {
      // Generate MongoDB queries
      //const keywordQuery = createKeywordQuery(keywords);
      const productQuery = createProductQuery(productName);
      const sortQuery = createSortQuery(sort);
      const filterQuery = filterToString(filters);

      // Make API call to /api/search with search term and sort order
      fetch(
        `/api/cards?limit=${limit}&skip=${skip}&search=${keywords}&filter=${encodeURIComponent(
          combineQueries(null, productQuery, filterQuery)
        )}&sort=${encodeURIComponent(sortQuery)}`,
      )
        .then((response) => response.json())
        .then((data: CardList) => {
          if (reset) {
            this.cards = data.data;
          } else {
            this.cards = this.cards.concat(data.data);
          }
          this.cardslist = data;
          // Handle API response
          // ...
        })
        .catch((error) => {
          // Handle API error
          // ...
        });
    },
  },
});
