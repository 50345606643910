import type { Filter } from "~/types/filter";

export function filterToString(filters: Filter[]): string | null {
  const filterObject: Record<string, (string | number)[]> = {};

  // Iterate through filters and populate filterObject
  for (const filter of filters) {
    const value =
      typeof filter.value.value === "object"
        ? (filter.value.value as Record<string, string>).en // Assume 'en' key exists if value is an object
        : filter.value;

    // Check if the field already exists in the filterObject
    if (filter.field in filterObject) {
      // If the field exists, append the value
      filterObject[filter.field].push(value);
    } else {
      // If the field doesn't exist, create an array with the value
      filterObject[filter.field] = [value];
    }
  }

  // Generate filter string
  let str = "";

  // Iterate through each key in filterObject
  for (const key in filterObject) {
    // Append each key and its corresponding values
    str += `${key}:in=[${filterObject[key].join(", ")}],`;
  }

  // Remove trailing comma
  str = str.slice(0, -1);

  if (str === "") {
    return null;
  }
  return str;
}

export const createSortQuery = (sortString: string): string => {
  try {
    const sortQuery = JSON.parse(sortString);
    const field = Object.keys(sortQuery)[0];
    const order = sortQuery[field];
    return order === -1 ? `-${field}` : field;
  } catch (error) {
    console.error(`Invalid sort string: ${sortString}`);
    return "";
  }
};

export const createProductQuery = (productName: string): string | null => {
  if (productName === "") {
    return null;
  }

  const query = `product:eq=${productName}`;
  return query;
};

export const createKeywordQuery = (keywordString: string): string | null => {
  if (keywordString === "") {
    return null;
  }
  // Split the string by "," and trim the resulting strings to remove whitespace
  const keywords = keywordString.split(",").map((keyword) => keyword.trim());

  // Generate the MongoDB query
  const query = `keywords:in=[${keywords}]`;

  return query;
};

export function combineQueries(
  keywordQuery: string | null,
  productQuery: string | null,
  filterQuery: string | null,
): string {
  const queries: string[] = [];

  if (keywordQuery !== null) {
    queries.push(keywordQuery);
  }

  if (productQuery !== null) {
    queries.push(productQuery);
  }

  if (filterQuery !== null) {
    queries.push(filterQuery);
  }

  return queries.join(",");
}

export function getObjectProperty(input: any, language: string): any {
  if (
    typeof input === "object" &&
    input.hasOwnProperty("de") &&
    input.hasOwnProperty("en")
  ) {
    if (language === "de") {
      console.log(input.de);
      return input.de;
    } else if (language === "en") {
      console.log(input.en);
      return input.en;
    }
  }

  return input;
}

export function addLanguageToInput(input: string, language: string): string {
  if (input === "card_class" || input === "card_color") {
    return `${input}.${language}`;
  }

  return input;
}
